<template>
  <div class="warpFun" :style="{ minHeight: minHeight + 'px' }">
    <!-- <div>
      <img src="~@/assets/new-icon/bg.png" alt="" />
    </div> -->
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      minHeight: 1280,
    };
  },
  created(){
    console.log(this.minHeight = document.documentElement.clientHeight);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.warpFun {
  background: #fff;
  min-width: 1280px;
}
</style>
